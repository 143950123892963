import { createApolloClient } from '@nhost/apollo';
import { NhostClient } from '@nhost/nhost-js';
import type { User } from '@nhost/vue';
import { NhostClient as NhostVueClient } from '@nhost/vue';
import {
  DefaultApolloClient,
  provideApolloClient
} from '@vue/apollo-composable';

let nhost: NhostClient | null = null;

export function useNhost() {
  if (nhost && process.client) {
    return nhost;
  }
  const nuxtApp = useNuxtApp();

  const runtimeConfig = useRuntimeConfig();

  if (
    !runtimeConfig.public.nhostSubdomain ||
    !runtimeConfig.public.nhostRegion
  ) {
    console.warn(
      'Nhost authentication is not available. Please check your Nhost configuration.'
    );
    return;
  }

  const nhostClient = new NhostVueClient({
    region: runtimeConfig.public.nhostRegion,
    subdomain: runtimeConfig.public.nhostSubdomain,
    clientStorageType: 'web',
    autoSignIn: false
  });

  const apolloClient = createApolloClient({
    nhost: nhostClient
  });

  nuxtApp.vueApp.use(nhostClient).provide(DefaultApolloClient, apolloClient);

  provideApolloClient(apolloClient);

  nhost = nhostClient;

  return nhost;
}

let user: Ref<User | null | false> | null = null;
let isAuthenticating: Ref<boolean | null> | null = null;
let isAuthenticated: Ref<boolean | null> | null = null;

export function useAuth(required: boolean | 'late' = false) {
  if (!process.client) {
    return {
      user: ref<User | null | false>(null),
      isAuthenticating: ref<boolean | null>(null),
      isAuthenticated: ref<boolean | null>(null)
    };
  }

  const { t } = useI18n();

  const nhost = useNhost();

  if (!user || !isAuthenticating || !isAuthenticated) {
    user = ref<User | null | false>(nhost.auth.getUser());
    isAuthenticating = ref<boolean | null>(
      nhost.auth.getAuthenticationStatus().isLoading
    );
    isAuthenticated = ref<boolean | null>(nhost.auth.isAuthenticated());

    nhost.auth.onAuthStateChanged(() => {
      const _isAuthenticating = nhost.auth.getAuthenticationStatus().isLoading;

      user.value = _isAuthenticating ? null : nhost.auth.getUser() || false;
      isAuthenticating.value = _isAuthenticating;
      isAuthenticated.value = nhost.auth.isAuthenticated();
    });
  }

  const authCookie = useCookie('authenticated');

  watch(
    () => user.value?.email,
    email => {
      if (
        Boolean(email) &&
        (!authCookie.value || authCookie.value === 'false')
      ) {
        authCookie.value = 'true';
      } else if (!email && authCookie.value === 'true') {
        authCookie.value = 'false';
      }

      // Push Hubspot event

      if (typeof window === 'undefined') {
        return;
      }

      if (!email) {
        return;
      }

      if (!window?._hsq) {
        return;
      }

      window.hubspotIdentified = true;
      window._hsq.push(['identify', { email: user.value.email }]);
    },
    { immediate: true }
  );

  const localePath = useLocalePath();

  const route = useRoute();

  const mounted = ref(false);

  const checkAuth = () => {
    if (
      process.client &&
      isAuthenticating.value === false &&
      isAuthenticated.value === false &&
      (required === true || (required === 'late' && mounted.value)) &&
      route.path !== localePath('/')
    ) {
      navigateTo(localePath('/'));
    }
  };

  watch(isAuthenticating, () => checkAuth(), { immediate: true });

  const { addToast } = useToasts();

  function checkSession() {
    const nhost = useNhost();
    const params = new URLSearchParams(window.location.search);
    const refreshToken = params.get('refreshToken');
    const redirectType = params.get('type');
    const error = params.get('error');

    if (error === 'invalid-ticket') {
      window.userPasswordResetEmail = params.get('email');
      window.invalidTicketError = true;
      addToast({
        title: t('auth.passwordResetError'),
        type: 'success'
      });
      // check if current route has routes.resetPassword in it
      if (route.path.includes(t('routes.resetPassword'))) {
        return;
      }
      navigateTo(
        localePath(`/${t('routes.account')}/${t('routes.resetPassword')}`)
      );
      return;
    }

    if (refreshToken) {
      nhost.auth.refreshSession(refreshToken).then(result => {
        if (['passwordReset', 'signinPasswordless'].includes(redirectType)) {
          if (!route.path.includes(t('routes.resetPassword'))) {
            navigateTo(
              localePath(`/${t('routes.account')}/${t('routes.resetPassword')}`)
            );
          }
          return;
        }

        if (result.error) {
          console.error(result.error);
        } else {
          addToast({
            title: t('common.signInSuccess'),
            type: 'success'
          });
        }
      });
      if (!['passwordReset', 'signinPasswordless'].includes(redirectType)) {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }
    }
  }

  onMounted(() => {
    mounted.value = true;
    checkAuth();
    checkSession();
  });

  return {
    user,
    isAuthenticating,
    isAuthenticated
  };
}
